<template>
  <v-badge
    v-if="role == 'technician'"
    content="1"
    :value="slavePlusRemote || 0"
    color="deep-orange accent-2"
    overlap
    offset-x="20"
    offset-y="12"
  >
    <v-chip
      text-color="white"
      :color="currentColor"
      class="py-4 px-5 mr-2"
      style="font-size: 14px; border-radius: 22px"
      @click="onHandleClick"
    >
      <v-icon small left>
        {{
          slavePlusRemote
            ? "mdi-eye-outline "
            : "mdi-eye-off-outline"
        }}
      </v-icon>
      Remotos
      <v-icon small right> mdi-account-plus </v-icon>
    </v-chip>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Aviso </v-card-title>

        <v-card-text>
          El Creativo: {{ currentUser }}, quiere unirse al estudio remotamente.
          ¿Desea dejar que se una?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            rounded
            depressed
            color="red"
            small
            dark
            width="100"
            @click="onDeclineCreative"
          >
            Denegar
          </v-btn>
          <v-btn
            rounded
            depressed
            color="admiraGreen"
            small
            dark
            width="100"
            @click="onAceptCreative"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Aviso </v-card-title>

        <v-card-text>
          ¿Desea expulsar al creativo: {{ currentUser }}, y finalizar su sesión?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            rounded
            depressed
            color="admiraGreen"
            small
            dark
            width="100"
            @click="onAceptCreative"
          >
            Expulsar
          </v-btn>
          <v-btn
            rounded
            depressed
            color="red"
            small
            dark
            width="100"
            @click="onDeclineCreative"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-badge>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      states: [
        { id: 0, color: "disabled" },
        { id: 1, color: "orange" },
        { id: 2, color: "admiraGreen" },
      ],
      currentState: 0,
      dialog: false,
      menu: false,
      dialog2: false,
    };
  },
  computed: {
    ...mapGetters(["showConnections"]),
    ...mapState(["role", "connectedToTechnician", "currentSlaveRemote", "requestSlaveRemote", "studio", "currentUser", "users"]),
    currentColor() {
      if(this.requestSlaveRemote == false) {
        if(this.users == null || this.users.slave_plus_remote == null) {
          return this.states[0].color
        }
      }
      const color = this.states.find((state) => state.id == this.currentState);
      return color.color;
    },
    slavePlusRemote() {
      if(this.users == null) return false
      if(this.users.slave_plus_remote == null) return false
      return true
    }
  },
  methods: {
    onHandleClick() {
      if(this.currentColor == 'disabled') return
      switch (this.currentState) {
        case 1:
          this.dialog = true;
          break;

        case 2:
          this.dialog2 = true;
          break;

        default:
          break;
      }
    },
    onAceptCreative() {
      if(this.currentState == 1) {
        this.dialog = false;
        this.currentState = 2;

        this.$globalData.socket.emit('responsePendingCreativeAccessRequest', { toNamespace: `ECI_ESTUDIO`, toRoom: `estudio${this.studio}`, responseRequest: "accessTrue", user: this.currentSlaveRemote });
      }
      else if(this.currentState == 2) {
        this.dialog2 = false
        this.currentState = 0
        this.$globalData.socket.emit('kickUser', { toNamespace: `ECI_ESTUDIO`, toRoom: `estudio${this.studio}`, kickUser: 'slave_plus_remote'} )
      }
      this.$store.state.requestSlaveRemote = false
    },
    onDeclineCreative() {
      if(this.currentState == 1) {
        this.dialog = false;
        this.currentState = 0;
        this.$globalData.socket.emit('responsePendingCreativeAccessRequest', { toNamespace: `ECI_ESTUDIO`, toRoom: `estudio${this.studio}`, responseRequest: "accessFalse", user: this.currentSlaveRemote });
      }
      else if(this.currentState == 2) {
        this.dialog2 = false
      }
      this.$store.state.requestSlaveRemote = false
    },
    checkState() {
      if(this.requestSlaveRemote == true) {
        this.currentState = 1
        return
      }
      if(this.users == null || this.users.slave_plus_remote == null) {
        this.currentState = 0
        return
      }
      this.currentState = 2
      
    },
  },
  watch: {
    requestSlaveRemote() {
      if(this.requestSlaveRemote == true) {
        this.currentState = 1
      }
    }
  },
  mounted() {
    this.checkState()
  }
};
</script>

<style>
</style>