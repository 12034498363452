<template>
  <v-sheet
    height="100%"
    class="overflow-scroll"
    style="position: relative; margin-top:0px; border-radius:0; "
  >
    <v-row align="center" class="ma-0 pa-5 background" >
      <v-row class="align-center">
        <div style="padding-left:0px;padding-right:0px">
          <v-btn @click="$router.push({name: 'home'})" icon plain >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <v-col cols="1" style="max-width: 85px;">
          <v-avatar color="admiraGreen" size="60">
            <v-icon style="font-size:31px;" dark>mdi-bullhorn-outline</v-icon>
          </v-avatar>
        </v-col>

        <v-col cols style>
          <h4
            style="font-size:28px; color:#777; font-weight:300; text-align:left; max-width: fit-content"
            class="ml-0 text--text"
          >Convocatorias</h4>
        </v-col>

        <v-col cols style="max-width:max-content;" class="ml-auto">
                <Connections/>
            </v-col>
            <v-col cols style="max-width:max-content;" class="ml-auto mr-6">
                <v-row class=""><p style="font-size: 14px;margin:0px" class="text--text">Estudio</p></v-row>
                <v-row class="mt-4">
                    <v-chip
                    class="py-4 px-5"
                    color="#926CB1"
                    text-color="white"
                    style="font-size: 14px;border-radius:22px;"
                    >
                    ESTUDIO {{studio}}
                    </v-chip>
                </v-row>
            </v-col>
      </v-row>
    </v-row>

    <v-divider style="border-width:1px;" class="background"/>

    <v-row align="center" class="ma-0 px-5 menu">
      <v-col style="max-width: 350px;" >
          <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="computedDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              class="datePicker"
              color="admiraGreen"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            locale="es"
            first-day-of-week=1
            @input="changedDay"
          ></v-date-picker>
        </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols style="max-width: max-content;" class="mr-2 aml-auto">
          Convocatorias: {{allCitations.length}}
        </v-col>
        <v-col cols style="max-width: max-content;" class=" ml-auto">
          Grabaciones: {{totalWedges}}
        </v-col>
    </v-row>
    <v-divider class="mx-8 menu" style="    margin: 0px !important;" />
    <v-row class="ma-0 pa-5 menu" :class="classObjectHeight">
      <v-container fluid>
        <v-data-iterator
          :items="allCitations"
          item-key="_id"
          :items-per-page="100"
          hide-default-footer
        >
          <template v-slot:default="{ items, isExpanded, expand }">
            <v-row v-for="citation in items" :key="citation._id" justify="start">
              <v-col cols="1">
                <v-avatar color="admiraGreen" size="58">
              <v-icon style="font-size:31px;" dark>mdi-bullhorn-outline</v-icon>
            </v-avatar>
              </v-col>
              <v-col cols="11" class="pt-4">
                <v-expansion-panels class="box-citation background" flat>
                    <v-expansion-panel class="box-citation box-citation-panel background">
                      <v-expansion-panel-header class="box-citation box-citation-panel-header background">
                        <v-row no-gutters>
                            <v-col cols="4">
                              <strong class="title admiraGreen--text">{{getTime(citation.initDate)}} - {{getTime(citation.endDate)}}</strong>
                            </v-col>
                            <v-col
                              cols="8"
                              class="subtitle-1 d-flex justify-end align-center pr-5"
                            >
                              Actividades: {{citation.activities.length}}
                            </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="box-citation-panel-content background">
                        <v-row no-gutters class="px-4">
                            <!-- <v-col cols="1" class="text-left d-flex align-center subtitle-2 grey--text">
                              Intervienen
                            </v-col> -->
                            <v-col
                              cols="12"
                              class="text-left"
                            >
                              <span class="text-left align-center subtitle-2 grey--text" style="display:inline-block; width:80px;">  Intervienen: </span>
                              <v-chip v-for="technician in citation.technicians" :key="technician._id" outlined color="red" class="ma-1 bg-white"><span class="gray-chips">{{technician.technicianId.name}}</span></v-chip>
                              <v-chip v-for="creative in citation.creatives" :key="creative._id" outlined color="yellow" class="ma-1 bg-white"><span class="gray-chips">{{creative.creativeId.name}}</span></v-chip>
                              <v-chip v-for="speaker in allSpeakers(citation)" :key="speaker._id" outlined color="green" class="ma-1 bg-white"><span class="gray-chips">{{speaker.alias}}</span></v-chip>
                            </v-col>
                          <v-col
                              cols="12"
                              class="text-left">
                              <span class="text-left align-center subtitle-2 grey--text" style="display:inline-block; width:80px;">  Medios: </span>
                              <v-chip v-for="language in getEventLanguage(citation.activities)" :key="language" outlined color="grey" class="ma-1 bg-white"><span class="gray-chips">{{language}}</span></v-chip>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12 pa-0">
                            <v-data-table
                              @click:row="showDetails(citation)"
                              hide-default-footer
                              :headers="headers"
                              :items="citation.activities"
                              item-key="name"
                              class="elevation-0 text--text"
                            >
                            <template v-slot:item.activityId="{ item }">
                              <span>{{ item.activityId.description }}</span>
                            </template>
                            <template v-slot:item.mediaId="{ item }">
                              <span>{{ item.mediaId.name }}</span>
                            </template>
                            <template v-slot:item.companyId="{ item }">
                              <span>{{ item.companyId.name }}</span>
                            </template>
                            <template v-slot:item.recordings="{ item }">
                              <span>{{ getTotalWeges(item) }}</span>
                            </template>
                          </v-data-table>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>
    </v-row>
  </v-sheet>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Connections from "@/components/Connections.vue"
import {timeFormat} from "@/mixins/timeFormat"

export default {
  name: "citations",
  components: {
     Connections
  },
  mixins: [timeFormat],
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10),
      initDate: new Date(new Date().setUTCHours(0,0,0,0)).getTime(),
      endDate: new Date(new Date().setUTCHours(22,0,0,0)).getTime(),
      deteOptions: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
      menu: false,
      totalWedges: 0,
      headers: [
        {
          text: 'Convocatoria',
          align: 'start',
          sortable: false,
          class:'text--text',
          value: 'activityId',
        },
        { text: 'Tipo', value: 'mediaId', class:'text--text', cellClass: 'text-text' ,sortable: false, },
        { text: 'Empresa', value: 'companyId', class:'text--text',  sortable: false,},
        { text: 'Cuñas', value: 'recordings', class:'text--text',  sortable: false, },

      ],
    };
  },
  computed: {
    ...mapState(["allCitations","actualCitation","studio","studios", "auxDateCitations"]),

    computedDate(){
      return this.capitalize(new Date(this.date).toLocaleDateString('es-ES', this.deteOptions))
    },

      classObjectHeight () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'height-scroll-xs-sm'
          case 'sm': return 'height-scroll-xs-sm'
          case 'md': return 'height-scroll'
          case 'lg': return 'height-scroll'
          case 'xl': return 'height-scroll'
        }
      },

  },
  methods: {
    ...mapActions( ["receiveCitations","selectActivity"]),

    getTime(timestamp) {
      var h = new Date(timestamp).getHours();
      var m = new Date(timestamp).getMinutes();

      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;

      var output = h + ":" + m;
      return output;
    },
    allSpeakers(citation) {
        const speakers = []

        citation.speakerGroups.forEach(group => {
          group.providers.forEach(speaker => {
            if(speakers.find(speak => speaker.providerId._id == speak._id) == null) {
              speakers.push(speaker.providerId)
            }
          })
        })
        return speakers
      },
    getEventLanguage(activities){
      const languages = [];

      activities.forEach( activity =>
          languages.push(activity.mediaId.language ? `${activity.mediaId.name} ${activity.mediaId.language}` : activity.mediaId.name)
      );

      return [...new Set(languages)];
    },
    getTotalWeges(activitie){
      let wedges = 0
      activitie.recordings.forEach(recording => {
        wedges += (recording.audioCode && Array.isArray(recording.audioCode)) ? recording.audioCode.length : 0
      });
      return wedges
    },

    capitalize(string){
      return string[0].toUpperCase() + string.slice(1);
    },
    colorStudio(n){
      return (n === this.studio) ? '#926cb1' : 'grey';
    },
    closeApp(){
      window.ipcRenderer.send('closeApp')
    },
    async showDetails(citation){
      await this.$store.commit("setCitation", citation);

      this.$store.commit("setAuxDateCitations", this.date)


     this.$router.push({
        name: "citationDetails",
      });
    },

    getTotal(){
      this.allCitations.forEach(citation => {
        citation.activities.forEach(activitie => {
          activitie.recordings.forEach(recording => {
            const num = (recording.audioCode && Array.isArray(recording.audioCode)) ? recording.audioCode.length : 0
            this.totalWedges += num
          })
        });
      });

    },
    async changedDay(){
      this.totalWedges = 0;
      this.initDate = new Date(new Date(this.date).setUTCHours(0,0,0,0)).getTime()
      this.endDate= new Date(new Date(this.date).setUTCHours(22,0,0,0)).getTime()
      await this.receiveCitations({initDate:this.initDate,endDate:this.endDate});
      await this.getTotal();
      this.menu = false

    },
  },
  watch: {

  },
  async created() {

    if(this.auxDateCitations != null) {
      this.date = this.auxDateCitations
    }
    this.initDate = new Date(new Date(this.date).setUTCHours(0,0,0,0)).getTime()
    this.endDate= new Date(new Date(this.date).setUTCHours(22,0,0,0)).getTime()


    await this.receiveCitations({initDate:this.initDate,endDate:this.endDate});
    await this.getTotal();
  },
};
</script>

<style scoped>
>>> .v-data-table__wrapper table > tbody > tr{
  cursor:pointer;
}
>>> .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row){
  border-bottom: none
}
>>> .v-data-table-header{
  /* background-color: #F5F5F5 !important; */

}
>>> .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
  font-size: 0.875rem !important;
  font-weight: 500;
  letter-spacing: 0.0071428571em !important;
  line-height: 1.375rem;
  font-family: "Roboto", sans-serif !important;
  /* color: #9e9e9e !important; */

}
>>> .v-chip.v-chip--outlined.v-chip.v-chip {
  background-color:white !important;
}
.box-citation{
  border: 1px solid #CECECE;
  border-radius:10px;
  /* color: #777777; */
}
>>> .v-data-table{
  border-radius: 0 0 10px 10px;
}
>>> .v-expansion-panel{
  border-radius:10px !important;
  background-color: #F5F5F5 !important;
}
>>> .expansion-panel-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
>>> .v-expansion-panel-content {
  border-radius: 0 0 10px 10px;
  background-color: #F5F5F5;
}

>>> .v-expansion-panel-content__wrap{
 padding: 0 12px 13px !important;
}

a {
  text-decoration: none;
}
>>> .v-navigation-drawer {
  width: 418px !important;
}

>>> . v-divider{
  background-color: #E0E0E0;
}
>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
  /* color: #777777; */
}
>>> .v-data-table > .v-data-table__wrapper > table > thead > tr > th:first-child, >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child{
  padding-left: 27px;
}
>>> .v-data-table > .v-data-table__wrapper > table > thead > tr > th:last-child, >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child{
  padding-right: 27px;
}
.gray-chips{
  color:#505050;
}
.height-scroll{
  height:calc(100vh - 265px);
  overflow-x: auto;
}
.height-scroll-xs-sm{
  height:calc(100vh - 400px);
  overflow-x: auto;
}
</style>
