import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      dark: true,
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: "#679940",
        secondary: "#424242",
        background:"#fff",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        navbar: "#2A2F34",
        navbar2: "#41474A",
        navbar3: "#689940",
        navbar4: "#7BA556",
        drawer: "#F5F5F5",
        activebtn: "#707070",
        // colors Admira
        admiraGreen: "#689840",
        menu:"#EEEEEE",
        text:"#777"
      },
      dark: {
        primary: "#679940",
        secondary: "#424242",
        background:"#444444",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        navbar: "#2A2F34",
        navbar2: "#41474A",
        navbar3: "#689940",
        navbar4: "#7BA556",
        drawer: "#F5F5F5",
        activebtn: "#707070",
        // colors Admira
        admiraGreen: "#689840",
        menu:"#1E1E1E",
        text:"#FFF",
      }
    },
  },
});
