<template>
  <v-sheet
    height="100%"
    style="position: relative; margin-top:0px; border-radius:0; "
  >
    <v-row align="center" class="ma-0 pa-5 header background">
        <v-row class="align-center">
            <v-col cols style>
            <h4
                class="ml-5 text--text"
            >Audio Studio</h4>
            </v-col>

            <v-col cols style="max-width:max-content;" class="ml-auto"> 
                <Connections/>
            </v-col>
            <v-col cols style="max-width:max-content;" class="ml-auto mr-6">
                <v-row class=""><p style="font-size: 14px;margin:0px" class="text--text">Estudio</p></v-row>
                <v-row class="mt-4">
                    <v-chip 
                    class="py-4 px-5"
                    color="#926CB1"
                    text-color="white"   
                    style="font-size: 14px;border-radius:22px;"          
                    >
                    ESTUDIO {{studio}}
                    </v-chip>
                </v-row>          
            </v-col>
        </v-row>
    </v-row>
    <div class="d-flex align-center justify-space-around menu">
        <v-col class="convocatorias colstyle pa-0 background" @click="$router.push({name: 'citations'})" cols="4">
            <v-row class="pstyle">
                <p  class="text--text">Convocatorias</p>
            </v-row>
            <v-divider/>
            <v-row class="rowstyle">         
                <v-avatar color="admiraGreen" size="130">
                    <v-icon style="font-size:60px;" dark>mdi-microphone-outline</v-icon>
                </v-avatar>
            </v-row>
            <v-row style="justify-content: center;">
                <p style="font-size:16px"  class="text--text">Modo autónomo</p>
            </v-row>
        </v-col>  
        <v-col cols="4" class="convocatorias colstyle pa-0 background" @click="$router.push({name: 'sessions'})">
            <v-row class="pstyle">
                <p  class="text--text">Sesión de grabación</p>
            </v-row>    
            <v-divider/>
            <v-row class="rowstyle">
                <v-avatar color="admiraGreen" size="130">
                    <v-icon style="font-size:60px;" dark>mdi-record-circle-outline</v-icon>
                </v-avatar>
            </v-row>
            <v-row style="justify-content: center;">
                <p style="font-size:16px"  class="text--text">Grabaciones realizadas</p>
            </v-row>
        </v-col>    
    </div>
    <ConfigDialog :dialog="dialog" :close="() => dialog = false" :reconnectSocket="reconnectSocket" />
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import Connections from "@/components/Connections.vue";
import { defineAsyncComponent } from "vue";


export default {
  name: "home",
  components: {
    Connections,
    Header: defineAsyncComponent(() => import("@/components/Header")),
    ConfigDialog: defineAsyncComponent(() => import("../dialogs/ConfigDialog.vue"))
  },
  data: () => ({
      dialog: false,
  }),
  computed: {
    ...mapState(["creatives","allowedCitations","studio"])
  },
  methods: {
    reconnectSocket() {
        this.$globalData.socket.disconnect();
        this.$globalData.socket.connect();
    }
  },
  created() {
    this.$store.commit("setAuxDateCitations", this.date)
    if(process.env.VUE_APP_MODE == "web") {
      this.$router.push({name: 'login'})
    }
  },
  }

</script>
<style scoped>
.header h4{  
    font-size: 32px;
    color:#777;
    font-weight:300;
    text-align:left;
}
.menu{
    height: calc(100% - 100px);
}
.pstyle{
    color:#777777;
    font-size:24px;
    font-weight: 300;
    margin: 15px 0 0 0;
    justify-content: center;
}
.colstyle{
    height: 340px;
    background-color:white;
    border-radius:25px;
    box-shadow: 0px 3px 6px #00000029;
}
.rowstyle{
    margin:45px 0;
    justify-content: center;
}
.convocatorias:hover {
 cursor: pointer;
};
</style>