import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios, { axiosPlugin } from './helpers/axios'; // Ajusta la ruta según tu estructura
import socket from './socket'
import Vue2Editor from "vue2-editor";

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(axiosPlugin);

Vue.use(Vue2Editor);

Vue.config.productionTip = false
Vue.prototype.$globalData = Vue.observable({
  socket
});
//Vue.prototype.$socket = socket
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')





