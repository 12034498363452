<template>
  <div id="app" style=" min-width: 100%; margin-top:0">
    <v-app v-if="loadingApp == false">
      <v-main
        style="position:fixed; top:0; min-width: 100%; min-height: -webkit-fill-available;"
      >
      <v-alert
            class="alertSocketConnection"
            prominent
            dense
            type="error"
            v-if="role !== 'technician' && currentUser != null"
            v-model="noTechnician"

          >
            <v-row align="center">
              <v-col class="grow">
               <p class="ma-0">Error. Se ha perdido la conexión con el Técnico.</p>
              </v-col>
            </v-row>
      </v-alert>
      <v-alert
            class="alertSocketConnection"
            prominent
            dense
            type="error"
            v-model="lostConnection"

          >
            <v-row align="center">
              <v-col class="grow">
               <p class="ma-0">Se ha perdido la conexión con el servidor. Intentando reconectar...</p>
              </v-col>
            </v-row>
      </v-alert>
        <Navbar v-if="currentRouter != 'showRecording'" />
        <v-alert v-if="txtError"
          dismissible
          type="error"
          @input="closeAlert"
          transition="slide-x-transition"
        >{{ txtError }}</v-alert>
        <v-alert style="z-index: 1000;" v-if="textSuccess"
          dismissible
          type="success"
          @input="closeNotification"
          transition="slide-x-transition"
        >{{ textSuccess }}</v-alert>
        <router-view />
      </v-main>
      <v-dialog v-model="showPortDialog" persistent max-width="300px">
        <v-card>
          <v-card-title class="headline">Cambiar Puerto</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="newPort"
              label="Nuevo Puerto"
              type="number"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="submitNewPort">Aceptar</v-btn>
            <v-btn color="red darken-1" text @click="showPortDialog = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
    </v-app>
    <v-overlay v-else :value="loadingApp">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import { mapMutations, mapState, mapActions} from 'vuex';


export default {
  name: "App",
  components: {
    Navbar,
  },
  data() {
    return {
      showPortDialog: false, 
      newPort: '',
      loadingApp: true,
    };
  },
  computed: {
    ...mapState(["dialogSocketConnection","role","txtError", "textSuccess", "users", "lostConnection", "currentUser","studio"]),
    noTechnician() {
      if(this.users.length == 0){
        return false
      } 
      return this.users.technician == null
    },
    currentRouter() {
      return this.$route.name
    }
  },
  methods: {
    ...mapActions(['getStudios',"manageError", "manageSuccess"]),

    closeAlert(){
      this.manageError('')
    },
    closeNotification() {
      this.manageSuccess('')
    },
    
    ...mapMutations(['setStudio','setTypeSlave']),
    
    setEstudioStore(studio){
      this.setStudio(studio)
    },

    async setTypeSlaveStore(type){
      return this.setTypeSlave(type)  
    },

    async getSudiosStore(){
      return await this.getStudios()
    },
    submitNewPort() {
      if (this.newPort) {      // Aquí envías el nuevo puerto al proceso principal de Electron
          window.ipcRenderer.send('actualizar-puerto', this.newPort);
        this.showPortDialog = false;
        this.newPort = ''; // Resetear el puerto después de enviarlo
      }
    },
  },

   created() {
    if(process.env.VUE_APP_MODE == "web") {
      this.loadingApp = false
      if(this.$route.name == "login") return
      this.$router.push({name: 'login'})
    }
    else {
      window.ipcRenderer.send('toMain', true)
      window.ipcRenderer.receive('fromMain', (data) => {
        const {host , port, studio, typeSlave} = data;
        (async ()=>{
          await this.getSudiosStore();
          await this.setStudio(studio);
          if (this.role==='slave') {
            if(typeSlave == null) {
              this.$router.push("homeSlave")
            }
            await this.setTypeSlaveStore(typeSlave);
            this.$globalData.socket.connect();
          } 
          else {
            this.$globalData.socket.connect();
          }
        })();
        setTimeout(() => {
          this.loadingApp = false
        }, 2000);
      })
    }
   
  },
  mounted() {
    if(process.env.VUE_APP_MODE !== "web") {
      window.ipcRenderer.receive('solicitar-cambio-puerto', () => {
        console.log("solicitar-cambio-puerto recibido");
        this.showPortDialog = true; // Mostrar el diálogo cuando se recibe el evento
      });
    }
    window.ipcRenderer.onAppClosing(() => {
      this.$globalData.socket.emit('kickUser', { toNamespace: `ECI_ESTUDIO`, toRoom: `estudio${this.studio}`, kickUser: 'slave_plus_remote'} )

      socket.close();
    });
  },
};
</script>
<style>

html {
  overflow: hidden !important;
}
</style>
<style scoped>

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  /* padding-top: -64px; */
  padding-left: 0;
  padding-right: 0;
  overflow: hidden !important;
}

>>> .v-dialog__content .alertSocketConnection{
  margin-bottom: 0px;
}

.alertSocketConnection  >>>.v-alert__wrapper{
  height: 60px;
}

  .v-alert {
    position: fixed;
    right: 20px;
    top: 20px;
    margin: 0 auto;
    z-index: 1;
  }

</style> 

