<template>
  <v-col style="max-width:max-content;" class="ml-auto mr-8">
    <v-row class="">
      <p style="font-size: 14px;margin:0px"  class="text--text">Conexiones</p>
    </v-row>
    <v-row class="mt-4">
      <v-chip
        v-if="role !== 'technician'"
        text-color="white"
        :color="technician ? '#749948' : 'disabled'"
        class="py-4 px-5 mr-2"
        style="font-size: 14px;border-radius:22px;"
      >
        <v-icon small left>
          {{ technician ? 'mdi-eye-outline ' : 'mdi-eye-off-outline' }}
        </v-icon>
        Técnico
        <v-icon small right>
          mdi-account-details
        </v-icon>
      </v-chip>
      <v-badge
          v-if="role !== 'slave_plus'"
          content="1"
          :value="slavePlus || 0"
          color="deep-orange accent-2"
          overlap
          offset-x="20"
          offset-y="12"
      >
        <v-chip
            text-color="white"
            :color="slavePlus ? '#749948' : 'disabled'"
            class="py-4 px-5 mr-2"
            style="font-size: 14px;border-radius:22px;"
        >
          <v-icon small left>
            {{ slavePlus == false ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
          </v-icon>
          Creativo
          <v-icon small right>
            mdi-account-plus
          </v-icon>
        </v-chip>
      </v-badge>
      <v-badge
          v-if="role !== 'slave'"
          content="1"
          :value="slave || 0"
          color="deep-orange accent-2"
          overlap
          offset-x="20"
          offset-y="12"
      >
        <v-chip
            :color="slave ? '#749948' : 'disabled'"
            class="py-4 px-5 mr-2"
            text-color="white"
            style="font-size: 14px;border-radius:22px;"
        >
          <v-icon small left>
            {{slave == false ? 'mdi-eye-off-outline ' : 'mdi-eye-outline' }}
          </v-icon>
          Locutor
          <v-icon small right>
            mdi-account-voice
           </v-icon>
        </v-chip>
      </v-badge>
      <SpeakerChip />
    </v-row>
  </v-col>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import SpeakerChip from "./SpeakerChip.vue";

export default {
  name: 'Connections',
  components: {
    SpeakerChip
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([]),
    ...mapState(["role","connectedToTechnician","users"]),
    slave() {
      if(this.users == null) return false
      if(this.users.slave == null) return false
      return true
    },
    slavePlus() {
      if(this.users == null) return false
      if(this.users.slave_plus == null) return false
      return true
    },
    technician() {
      if(this.users == null) return false
      if(this.users.technician == null) return false
      return true
    }
  }
}
</script>
